import React, { useState, useEffect } from 'react'
import { useHistory, BrowserRouter, Route, Link } from "react-router-dom";
import ReactSession from '../../ReactSession';

const BottomSheetReservation = () => {
  const currentCompany = ReactSession.get("current_company");
  const currentUser = ReactSession.get("current_user");
  const current_order_count = ReactSession.get("current_order_count");

  useEffect(() => {
    debugger
  }, []);

  return (
    <div className='bottom-sheet'>
      <ul>
        <li>
          <Link to="/dashboard" style={{ background: (currentCompany.themeplate === 'App Theme 1' ? 'white' : currentCompany.company_color2 ) }}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>public</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>Home</div>
          </Link>
        </li>
        <li>
          <Link to="/products" style={{ background: (currentCompany.themeplate === 'App Theme 1' ? 'white' : currentCompany.company_color2 ) }} className={window.location.pathname === '/products' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>content_paste_search</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>Menu</div>
          </Link>
        </li>

        <li>
          <Link to="/rooms" style={{ background: (currentCompany.themeplate === 'App Theme 1' ? 'white' : currentCompany.company_color2 ) }} className={window.location.pathname === '/rooms' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>date_range</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>Reservasi</div>
          </Link>
        </li>
        <li>
          <Link to="/orders" style={{ background: (currentCompany.themeplate === 'App Theme 1' ? 'white' : currentCompany.company_color2 ) }} className={window.location.pathname === '/orders' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>content_paste_go</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>Pesanan</div>
          </Link>
        </li>
        <li>
          <Link to="/profile" style={{ background: (currentCompany.themeplate === 'App Theme 1' ? 'white' : currentCompany.company_color2 ) }} className={window.location.pathname === '/profile' ? `active` : ``}>
            <i className="material-icons" style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>account_circle</i> 
            <div style={{color: (currentCompany.themeplate === 'App Theme 1' ? currentCompany.company_color1 : 'white' ) }}>Profil Saya</div>
          </Link>
        </li>

      </ul>
    </div>
  )
}

export default BottomSheetReservation
